/**
 * 界面基础数据
 * @returns 
 */
export function baseData() {
    return {
        searchData: [
            {
                title: '老人姓名',
                value: "",
                type: "input",
                key: "name"
            },
            {
                title: '任务状态',
                value: "",
                type: "select",
                key: "status",
                options:[
                    {id:"w",value:"待执行"},
                    {id:"y",value:"已完成"},
                    {id:"n",value:"未完成"},
                ]
            },
            {
                title: '任务类型',
                value: "",
                type: "select",
                key: "type",
                options:[
                    {id:"daily",value:"日常任务"},
                    {id:"system",value:"系统任务"},
                    {id:"mall",value:"商城任务"},
                ]
            },
            {
                title: '任务时间',
                value: ["",""],
                type: "time",
                key: "time"
            }
        ],
        searchButton: [
            // {
            //     name: "新增",
            //     icon: "el-icon-circle-plus-outline",
            //     key: "addTask"
            // }
        ],
        menuData: [
            {
                key: "titleName",
                title: "任务名称",
                width: ""
            },
            {
                key: "type",
                title: "任务类型",
                width: "",
                isChange: true
            },
            {
                key: "status",
                title: "任务状态",
                width: "120",
                isChange: true
            },
            {
                key: "employeeName",
                title: "执行员工",
                width: "180"
            },
            {
                key: "needTime",
                title: "任务开始时间",
                width: "180"
            },
            {
                key: "operTime",
                title: "完成时间",
                width: "180"
            },
            {
                key: "appraise",
                title: "客户评价",
                width: "180"
            },
            {
                key: "remark",
                title: "备注",
                width: "120"
            },
        ],
        tableButton: [
            {title:"详情",key:"details"},
            {title:"删除",key:"del"},
        ]
    }
}

/**
 * 任务详情页
 * @param {*} key 
 * @returns 
 */
export function detailsData(key){
    let data = []
    switch (key) {
        case "system":
            // 系统任务
            data= [
                {
                    title:"任务名称",
                    type:"text",
                    value:"",
                    key:"titleName",
                    width:"30%"
                },
                {
                    title:"任务类型",
                    type:"change-text",
                    value:"",
                    model:"task",
                    key:"type",
                    width:"30%",
                },
                {
                    title:"任务状态",
                    type:"change-text",
                    value:"",
                    model:"task",
                    key:"status",
                    width:"30%",
                },
                {
                    title:"员工名称",
                    type:"text",
                    value:"",
                    key:"employeeName",
                    width:"30%"
                },
                {
                    title:"执行时间",
                    type:"text",
                    value:"",
                    key:"needTime",
                    width:"30%"
                },
                // {
                //     title:"家属点赞",
                //     type:"appraise",
                //     value:"",
                //     key:"appraise",
                //     width:"30%"
                // },
                {
                    title:"备注",
                    type:"text",
                    value:"",
                    key:"remark",
                    width:"70%"
                },
                {
                    title:"家属评价",
                    type:"text",
                    value:"",
                    key:"appraise",
                    width:"70%"
                },
                {
                    title:"任务配图",
                    type:"show-images",
                    value:"",
                    key:"images",
                    width:"70%"
                },
                {
                    title:"",
                    type:"divider",
                },
                {
                    title:"任务详情",
                    type:"task-content",
                    value:"",
                    model:"task",
                    key:"content",
                    width:"100%"
                },
            ]
            break
        case "daily":
            // 日常任务
            data= [
                {
                    title:"任务名称",
                    type:"text",
                    value:"",
                    key:"titleName",
                    width:"30%"
                },
                {
                    title:"任务类型",
                    type:"change-text",
                    value:"",
                    model:"task",
                    key:"type",
                    width:"30%",
                },
                {
                    title:"任务状态",
                    type:"change-text",
                    value:"",
                    model:"task",
                    key:"status",
                    width:"30%",
                },
                {
                    title:"员工名称",
                    type:"text",
                    value:"",
                    key:"employeeName",
                    width:"30%"
                },
                {
                    title:"执行时间",
                    type:"text",
                    value:"",
                    key:"needTime",
                    width:"30%"
                },
                {
                    title:"家属点赞",
                    type:"appraise",
                    value:"",
                    key:"appraise",
                    width:"30%"
                },
                {
                    title:"备注",
                    type:"text",
                    value:"",
                    key:"remark",
                    width:"70%"
                },
                {
                    title:"家属评价",
                    type:"text",
                    value:"",
                    key:"appraise",
                    width:"70%"
                },
                {
                    title:"任务配图",
                    type:"show-images",
                    value:"",
                    key:"images",
                    width:"70%"
                },
                {
                    title:"",
                    type:"divider",
                },
                {
                    title:"任务详情",
                    type:"task-content",
                    value:"",
                    key:"content",
                    width:"70%"
                },
            ]
            break;
    
        default:
            break;
    }
    return data
}
