import Vue from 'vue'

/**
 * 跳转的tag标签
 * @param {*} name 标签名称
 * @param {*} query 标签携带的参数
 * @param {*} path 跳转链接
 * @param {*} activeMenu 左侧相应菜单
 */
export function returnTag(name, query, path, fullPath, activeMenu) {
    let tag = {
        path: path,
        fullPath: fullPath,
        query: query,
        name: name,
        meta: {
            title: name,
            activeMenu: activeMenu
        },
    }
    return tag
}

/**
 * 状态码转换文字
 * @param {*} model 当前模块
 * @param {*} key 当前对应的属性
 * @param {*} code 当前对应状态码
 * @returns 返回状态码对应含义
 */
export function getStrByCode(model, key, code) {
    let str = ''
    switch (model) {
        case 'elderly':
            // 老人基础信息 表
            switch (key) {
                case 'status':
                    // 老人状态
                    switch (code) {
                        case "stay":
                            // 在院
                            str = '正常'
                            break;
                        case "out":
                            // 出院
                            str = '已离院'
                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
            break;
        case "employ":
            // 员工基础信息
            switch (key) {
                case "isEmployed":
                    switch (code) {
                        case "value":
                            
                            break;
                    
                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
            break
        case "task":
            // 员工基础信息
            switch (key) {
                case "type":
                    switch (code) {
                        case "daily":
                            str = '日常任务'
                            break;
                        case "system":
                            str = '系统任务'
                            break;
                        case "mall":
                            str = '商城任务'
                            break;
                    
                        default:
                            break;
                    }
                    break;
                case "status":
                    switch (code) {
                        case "w":
                            str = '待执行'
                            break;
                        case "y":
                            str = '已完成'
                            break;
                        case "n":
                            str = '未完成'
                            break;
                    
                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
            break

        default:
            break;
    }
    return str
}

/**
 * 返回所有可见按钮菜单
 * @param {*} model 模块
 * @param {*} authButtonKey 权限key数组
 * @returns 
 */
export function getButtonByModel(model, authButtonKey) {
    let array = []
    switch (model) {
        case "value":
            array = []
            break;

        default:
            break;
    }
    let list = []
    array.forEach(ele => {
        if (ele.key == 'search') {
            list.push(ele)
        }
        if (authButtonKey.indexOf(ele.key) >= 0) {
            list.push(ele)
        }
    });
    return list
}

/**
 * 返回权限中按钮的key
 * @param {*} authButton 
 * @returns 
 */
export function getButtonKey(authButton) {
    let keyList = []
    authButton.forEach(element => {
        keyList.push(element.key)
    });
    return keyList
}

/**
 * 将界面上的问卷数据转出接口所需数据格式
 * @param {*} array 
 * @returns 
 */
export function changeQuestionnaireRecord2Interface(array) {
    let questions = JSON.parse(JSON.stringify(array));
    questions.forEach((element) => {
        let answer = JSON.parse(JSON.stringify(element.answer));
        switch (element.questionType) {
            case "selectOne":
                element.optionsAnswer.forEach((ele) => {
                    if (ele.name == answer) {
                        ele.answer = true;
                    } else {
                        ele.answer = false;
                    }
                });
                break;
            case "selectMore":
                element.optionsAnswer.forEach((ele) => {
                    if (answer.indexOf(ele.name) >= 0) {
                        ele.answer = true;
                    } else {
                        ele.answer = false;
                    }
                });
                break;

            default:
                break;
        }
        delete element['answer']
    });

    return questions
}

/**
 * 将接口获取到的数据转化为界面所需的数据格式
 * @param {*} array 
 * @returns 
 */
export function changeQuestionnaireRecord2Page(array) {
    array.forEach(element => {
        let answer = element.questionType == "selectOne" ? "" : []
        switch (element.questionType) {
            case "selectOne":
                element.optionsAnswer.forEach(ele => {
                    if (ele.answer) {
                        answer = ele.name
                    }
                });
                break;
            case "selectMore":
                answer = []
                element.optionsAnswer.forEach(ele => {
                    if (ele.answer) {
                        answer.push(ele.name)
                    }
                });
                break

            default:
                break;
        }
        Vue.set(element, 'answer', answer)
    });
    return array
}