<template>
  <div id="edit-value">
    <!-- 编辑界面 -->
    <div class="item" v-for="item in data" :style="{ width: item.width }">
      <div class="title">
        <i class="el-icon-star-on" v-if="item.isNotNull"></i>
        <div class="va">
          {{ item.title }}
          <span v-if="item.subTitle">{{ item.subTitle }}</span>
        </div>
      </div>
      <div class="val">
        <el-divider v-if="item.type == 'divider'"></el-divider>
        <span v-if="item.type == 'text'">{{
          item.value == null || item.value == "" ? "暂无" : item.value
        }}</span>
        <span v-if="item.type == 'change-text'">{{
          changeCode(item.model, item.key, item.value)
        }}</span>
        <span v-if="item.type == 'appraise'">
          <i
            class="iconfont icon-dianzan"
            :style="{ color: (item.value != null && item.value != '') ? '#f0af0c' : '' }"
          ></i>
        </span>
        <div
          class="show-img"
          v-if="item.type == 'show-images'"
          v-viewer="{ movable: false }"
        >
          <el-empty
            description="暂无图片"
            v-if="item.value.length == 0"
          ></el-empty>
          <img
            :src="img"
            v-for="img in item.value"
            alt=""
            style="width: 160px; height: 90px"
          />
        </div>
        <div class="task-content" v-if="item.type == 'task-content'">
          <el-empty
            description="暂无任务详情"
            v-if="item.value.length == 0"
          ></el-empty>
          <div class="item" v-for="it in item.value">
            <div class="it">
              <div class="ti">任务标题</div>
              <div class="val">{{ it.name }}</div>
            </div>
            <div class="it">
              <div class="ti">任务描述</div>
              <div class="val">{{ it.remark }}</div>
            </div>
            <div class="it">
              <div class="ti">任务状态</div>
              <div class="val">
                {{ changeCode('task', "status", it.status) }}
              </div>
            </div>
            <div class="it">
              <div class="ti">建议执行时间</div>
              <div class="val">{{ it.taskCreateTime }}</div>
            </div>
            <div class="it">
              <div class="ti">任务周期</div>
              <div class="val">{{ it.cycleName }}</div>
            </div>
          </div>
        </div>
        <el-input
          v-if="item.type == 'input'"
          v-model="item.value"
          :placeholder="'请输入' + item.title"
          clearable
        ></el-input>
        <el-date-picker
          v-if="item.type == 'date'"
          v-model="item.value"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
        <el-radio-group v-model="item.value" v-if="item.type == 'radio'">
          <template v-for="op in item.options">
            <el-radio :label="op.value">{{ op.label }}</el-radio>
          </template>
        </el-radio-group>
        <div v-if="item.type == 'files'" class="upload-files">
          <div
            class="upload-file text"
            v-for="(path, paIndex) in item.value"
            :key="paIndex"
          >
            {{ path.name }}
            <div class="mb">
              <i
                class="el-icon-delete"
                @click="deletePath(item.value, paIndex)"
              ></i>
            </div>
          </div>
          <div class="upload-file text">
            <el-upload
              class="upload-demo"
              :action="item.uploadData.action"
              :headers="item.uploadData.headers"
              :show-file-list="false"
              multiple
              :data="item.uploadData.data"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
            >
              <span>
                <i class="el-icon-upload"></i>
              </span>
            </el-upload>
          </div>
        </div>
        <div v-if="item.type == 'img'" class="upload-file">
          <el-upload
            class="upload-demo"
            :action="item.uploadData.action"
            :headers="item.uploadData.headers"
            :show-file-list="false"
            multiple
            :data="item.uploadData.data"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
            <span v-if="item.value != null && item.value != ''">
              <img :src="item.value" />
            </span>
            <span v-else>
              <i class="el-icon-upload"></i>
            </span>
          </el-upload>
        </div>
        <div class="radio-input" v-if="item.type == 'radio-input'">
          <div class="to">
            <el-radio-group v-model="item.value.value">
              <el-radio :label="op.value" v-for="op in item.options">{{
                op.label
              }}</el-radio>
            </el-radio-group>
          </div>
          <div class="bu">
            <div class="ti">备注:</div>
            <div class="va">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入备注"
                v-model="item.value.mes"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="radio-input" v-if="item.type == 'checkbox-input'">
          <div class="to">
            <el-checkbox-group v-model="item.value.value">
              <el-checkbox
                :label="op.value"
                v-for="op in item.options"
              ></el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="bu">
            <div class="ti">备注:</div>
            <div class="va">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入备注"
                v-model="item.value.mes"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit_value",
  props: {
    editValue: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.editValue,
    };
  },
  watch: {
    editValue() {
      this.data = this.editValue;
    },
    data(newValue) {
      this.$emit("showEditValueData", this.data);
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      this.$emit("beforeAvatarUpload", file);
    },
    handleAvatarSuccess(response, file, fileList) {
      this.$emit("handleAvatarSuccess", response);
    },
    deletePath(array, index) {
      array.splice(index, 1);
    },

    changeCode(model, key, code) {
      return this.$base.getStrByCode(model, key, code);
    },
  },
};
</script>

<style lang="less" scoped>
#edit-value {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  > .item {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    > .title {
      width: 120px;
      font-size: 15px;
      display: flex;
      justify-content: flex-end;
      padding-right: 8px;
      box-sizing: border-box;
      > i {
        margin-right: 5px;
        color: #ff4800;
      }
      > .va {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        > span {
          font-size: 13px;
          color: #6e80f5;
        }
      }
    }
    > .val {
      width: 0;
      flex: 1;
      box-sizing: border-box;
      /deep/.el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-radio {
        width: 120px;
      }
      > .show-img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        > img {
          margin: 0 10px 10px 0;
        }
      }
      > .task-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        > .item {
          width: 70%;
          background-color: #ebebebb4;
          display: flex;
          flex-wrap: wrap;
          border-radius: 5px;
          margin-bottom: 10px;
          padding: 10px;
          box-sizing: border-box;
          >.it{
            width: 50%;
            display: flex;
            font-size: 14px;
            margin-bottom: 8px;
            >.ti{
              width: 100px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-weight: 600;
              margin-right: 7px;
            }
          }
        }
      }
      > .radio-input {
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        > .to {
          width: 100%;
          display: flex;
          height: auto;
          flex-wrap: wrap;
          /deep/.el-radio-group {
            width: 100%;
          }
          /deep/.el-radio,
          /deep/.el-checkbox {
            width: 48%;
            margin: 6px 0;
          }
        }
        > .bu {
          width: 100%;
          height: auto;
          margin-top: 10px;
          display: flex;
          > .ti {
            font-size: 14px;
            margin-right: 5px;
          }
          > .va {
            width: 0;
            flex: 1;
            /deep/.el-textarea {
              width: 100%;
            }
          }
        }
      }
      .upload-file {
        width: 160px;
        height: 160px;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8a8a8a;
        font-size: 45px;
        img {
          width: 160px;
          height: 160px;
        }
      }

      .text {
        width: 100px;
        height: 100px;
        font-size: 13px;
        cursor: pointer;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;

        i {
          font-size: 25px;
        }

        .mb {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          background-color: #0303036e;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5;
          color: #fff;

          > i {
            font-size: 15px;
            margin: 0 5px;
          }
        }

        &:hover .mb {
          opacity: 1;
        }
      }
    }
  }
}
</style>