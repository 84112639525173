/**
 * 界面基础数据
 * @returns 
 */
export function baseData() {
    return {
        searchData: [
            {
                title: '老人姓名',
                value: "",
                type: "input",
                key: "name"
            }
        ],
        searchButton: [
            {
                name: "新增",
                icon: "el-icon-circle-plus-outline",
                key: "addElderly"
            }
        ],
        menuData: [
            {
                key: "name",
                title: "姓名",
                width: "80"
            },
            {
                key: "idCardNumber",
                title: "身份证号",
                width: ""
            },
            {
                key: "bedName",
                title: "床位号",
                width: ""
            },
            {
                key: "employeeName",
                title: "护工姓名",
                width: "120"
            },
            {
                key: "age",
                title: "年龄",
                width: "80"
            },
            {
                key: "height",
                title: "身高(cm)",
                width: "80"
            },
            {
                key: "weight",
                title: "体重(kg)",
                width: "80"
            },
            {
                key: "gender",
                title: "性别",
                width: "60"
            },
            {
                key: "dateOfBirth",
                title: "生日",
                width: "120"
            },
            {
                key: "status",
                title: "状态",
                width: "120",
                isChange: true
            },
            {
                key: "homeAddress",
                title: "家庭住址",
                width: ""
            },
        ],
        tableButton: [
            {title:"详情",key:"details"},
            {title:"联系人",key:"contacts"},
            {title:"删除",key:"del"},
            {title:"分配",key:"allocation"},
            {title:"基础情况",key:"questionnaire"},
            {title:"健康监测",key:"health"},
            {title:"日常护理",key:"care"},
        ]
    }
}

export function editBaseData() {
    return [
        {
            width: "70%",
            isNotNull: false,
            type: "img",
            title: "照片",
            key: "image",
            value: "",
            uploadData: {
                action: process.env.VUE_APP_BASE_API + "nursinghomes/file/uploadFile",
                headers: {
                    Authorization: process.env.VUE_APP_TOKEN + localStorage.getItem("isToken"),
                },
                data: {
                    title: "老人头像",
                    key:"image"
                },
            },
        },
        {
            width: "45%",
            isNotNull: true,
            type: "input",
            title: "姓名",
            key: "name",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "input",
            title: "身份证号",
            key: "idCardNumber",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "date",
            title: "出生日期",
            key: "dateOfBirth",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "input",
            title: "年龄",
            key: "age",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "input",
            title: "身高",
            key: "height",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "input",
            title: "体重",
            key: "weight",
            value: ""
        },
        {
            width: "45%",
            isNotNull: false,
            type: "radio",
            title: "性别",
            key: "gender",
            value: "男",
            options:[
                {label:"男",value:"男"},
                {label:"女",value:"女"},
            ]
        },
        {
            width: "45%",
            isNotNull: false,
            type: "input",
            title: "家庭住址",
            key: "homeAddress",
            value: ""
        },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"checkbox-input",
        //         title:"医疗费用",
        //         subTitle:"* 多选",
        //         key:"age",
        //         options:[
        //             {label:"城镇职工基本医疗保险",value:"城镇职工基本医疗保险"},
        //             {label:"城乡居民基本医疗保险",value:"城乡居民基本医疗保险"},
        //             {label:"自费",value:"自费"},
        //             {label:"公务员补助",value:"公务员补助"},
        //             {label:"企业补充保险",value:"企业补充保险"},
        //             {label:"工费医疗及医疗照顾对象",value:"工费医疗及医疗照顾对象"},
        //             {label:"医疗救助",value:"医疗救助"},
        //             {label:"大病保险",value:"大病保险"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"checkbox-input",
        //         title:"居住状况",
        //         subTitle:"* 多选",
        //         key:"age",
        //         options:[
        //             {label:"城镇职工基本医疗保险",value:"城镇职工基本医疗保险"},
        //             {label:"城乡居民基本医疗保险",value:"城乡居民基本医疗保险"},
        //             {label:"自费",value:"自费"},
        //             {label:"公务员补助",value:"公务员补助"},
        //             {label:"企业补充保险",value:"企业补充保险"},
        //             {label:"工费医疗及医疗照顾对象",value:"工费医疗及医疗照顾对象"},
        //             {label:"医疗救助",value:"医疗救助"},
        //             {label:"大病保险",value:"大病保险"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"checkbox-input",
        //         title:"疾病诊断",
        //         subTitle:"* 可多选",
        //         key:"age",
        //         options:[
        //             {label:"高血压病I10-I15",value:"高血压病I10-I15"},
        //             {label:"冠心病I25",value:"冠心病I25"},
        //             {label:"糖尿病E10-E14",value:"糖尿病E10-E14"},
        //             {label:"肺炎J12-J18",value:"肺炎J12-J18"},
        //             {label:"慢性阻塞性疾病J44",value:"慢性阻塞性疾病J44"},
        //             {label:"脑出血I30-I62",value:"脑出血I30-I62"},
        //             {label:"脑梗塞I64",value:"脑梗塞I64"},
        //             {label:"尿路感染(30天内)",value:"尿路感染(30天内)"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"checkbox-input",
        //         title:"经济来源",
        //         subTitle:"* 可多选",
        //         key:"age",
        //         options:[
        //             {label:"退休金",value:"退休金"},
        //             {label:"子女补贴",value:"子女补贴"},
        //             {label:"亲友资助",value:"亲友资助"},
        //             {label:"国家惠普型补贴",value:"国家惠普型补贴"},
        //             {label:"个人贮蓄",value:"个人贮蓄"},
        //             {label:"其他补贴",value:"其他补贴"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"66%",
        //         isNotNull:false,
        //         type:"checkbox-input",
        //         title:"风险事件",
        //         subTitle:"* 可多选",
        //         key:"age",
        //         options:[
        //             {label:"退休金",value:"退休金"},
        //             {label:"子女补贴",value:"子女补贴"},
        //             {label:"亲友资助",value:"亲友资助"},
        //             {label:"国家惠普型补贴",value:"国家惠普型补贴"},
        //             {label:"个人贮蓄",value:"个人贮蓄"},
        //             {label:"其他补贴",value:"其他补贴"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"radio-input",
        //         title:"体质指数",
        //         subTitle:"* BBMI",
        //         key:"age",
        //         options:[
        //             {label:"低于BMI正常值",value:"低于BMI正常值"},
        //             {label:"正常",value:"正常"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"radio-input",
        //         title:"吞咽困难",
        //         key:"age",
        //         options:[
        //             {label:"有",value:"有"},
        //             {label:"无",value:"无"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
        //     {
        //         width:"33%",
        //         isNotNull:false,
        //         type:"radio-input",
        //         title:"褥疮",
        //         key:"age",
        //         options:[
        //             {label:"有",value:"有"},
        //             {label:"无",value:"无"},
        //         ],
        //         value:{
        //             value:[],
        //             mes:""
        //         }
        //     },
    ]
}