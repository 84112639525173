<template>
  <div id="equipment">
    <!-- 设备管理 -->
    <div class="con">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="房间管理" name="first"></el-tab-pane>
        <el-tab-pane label="设备管理" name="second"></el-tab-pane>
      </el-tabs>
      <div class="cc customScrollBar">
        <el-collapse accordion v-if="tableData.length > 0">
          <template v-for="item in tableData">
            <el-collapse-item>
              <template slot="title">
                {{ item.name }}
              </template>
              <div class="conn">
                <div class="title">
                  房间号:{{ item.name }}
                  <i
                    class="el-icon-edit-outline"
                    style="margin-left: 8px; color: #4568e6"
                    @click="handleButClick('edit', item)"
                  ></i>
                  <i
                    class="el-icon-delete"
                    style="margin-left: 8px; color: #e66a6a"
                    @click="handleButClick('del', item)"
                  ></i>
                  <i
                    class="el-icon-circle-plus"
                    style="margin-left: 8px; color: #4568e6"
                    @click="handleButClick('add', item)"
                  ></i>
                </div>
                <div class="imgs" v-viewer="{ movable: false }">
                  <img
                    :src="img"
                    v-for="img in item.images"
                    alt=""
                    style="width: 160px; height: 90px"
                  />
                </div>
                <div class="tab">
                  <el-table :data="item.children" style="width: 60%" border>
                    <el-table-column
                      prop="bedNumber"
                      label="床位号"
                      width="230"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="sleepDeviceNumber"
                      label="睡眠仪编号"
                      width="230"
                    >
                      <template slot-scope="scope">
                        <span>{{
                          scope.row.sleepDeviceNumber == null ||
                          scope.row.sleepDeviceNumber == ""
                            ? "暂未绑定睡眠仪"
                            : scope.row.sleepDeviceNumber
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作">
                      <template slot-scope="scope">
                        <el-button type="text" size="small">编辑</el-button>
                        <el-button type="text" size="small">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-collapse-item>
          </template>
        </el-collapse>
        <el-empty description="暂无房间" v-else>
          <el-button type="primary" @click="handleButClick('add', {})"
            >添加房间</el-button
          >
        </el-empty>
      </div>
    </div>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import * as EquipmentData from "./equipment.js";
import {
  getRoomListByPage,
  addOrUpdateBedList,updateRoomInfo
} from "@/api/equipment/equipment.js";
export default {
  data() {
    return {
      activeName: "first",
      searchData: EquipmentData.baseData().searchData,
      searchButton: EquipmentData.baseData().searchButton,
      tableData: [],
      tableMenu: EquipmentData.baseData().menuData,
      model: "equipment",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
    };
  },
  methods: {
    async initTable() {
      let resultData = await getRoomListByPage({});
      this.tableData = resultData.list;
    },
    handleClick(tab, event) {},
    async handleButClick(key, item) {
      switch (key) {
        case "edit":
          // 编辑房间
          this.dialogData.tempData = item;
          this.openDialog("edit");
          let daa = {
            roomNumber:item.name,
            roomNum:item.children.length,
          }
          this.dialogData.data = this.$total.json2array(
            this.dialogData.data,
            daa
          );
          // let numData = []
          // item.children.forEach(element => {
          //   let dd = {
          //     value:element.bedNumber,
          //     id:element.id
          //   }
          //   numData.push(dd)
          // });
          // this.dialogData.data[1].numData = numData
          this.dialogData.flag = true;
          break;
        case "del":
          // 删除房间
          break;
        case "add":
          // 添加房间
          this.dialogData.tempData = {};
          this.openDialog("add");
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    openDialog(key) {
      switch (key) {
        case "add":
          this.dialogData.title = "添加房间";
          this.dialogData.data = [
            {
              title: "房间号",
              type: "input",
              value: "",
              key: "roomNumber",
              isNotNull: true,
            },
            {
              title: "床位数量",
              type: "input-change",
              value: "",
              key: "roomNum",
              isNotNull: true,
              numData: [],
            },
          ];
          this.dialogData.buttonList = [
            { active: "makesureAdd", text: "确认" },
            { active: "cancel", text: "取消" },
          ];
          break;

        case "edit":
          this.dialogData.title = "编辑房间";
          this.dialogData.data = [
            {
              title: "房间号",
              type: "input",
              value: "",
              key: "roomNumber",
              isNotNull: true,
            },
            // {
            //   title: "床位数量",
            //   type: "input-change",
            //   value: "",
            //   key: "roomNum",
            //   isNotNull: true,
            //   numData: [],
            // },
          ];
          this.dialogData.buttonList = [
            { active: "makesureEdit", text: "确认" },
            { active: "cancel", text: "取消" },
          ];
          break;

        default:
          break;
      }
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "cancel":
          this.colseDialog();
          break;
        case "makesureAdd":
          // 确定添加房间信息
          let dataList = [];
          this.dialogData.data[1].numData.forEach((element) => {
            let dd = {
              bedNumber: element.value,
              bedImg: [],
              status: "y",
            };
            dataList.push(dd);
          });
          requestData = {
            dataList: dataList,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
          await addOrUpdateBedList(requestData);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          await this.initTable();
          this.colseDialog();
          break;

        case "makesureEdit":
          // 确定添加房间信息
          // let dataList1 = [];
          // this.dialogData.data[1].numData.forEach((element) => {
          //   let dd = {
          //     bedNumber: element.value,
          //     id:element.id,
          //     bedImg: [],
          //     status: "y",
          //   };
          //   dataList1.push(dd);
          // });
          requestData = {
            id:this.dialogData.tempData.id,
            // dataList: dataList1,
          };
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );
          await updateRoomInfo(requestData);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          await this.initTable();
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    await this.initTable();
  },
};
</script>

<style lang="less" scoped>
#equipment {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
    > .el-tabs {
      width: 100%;
    }
    > .cc {
      width: 100%;
      height: 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      /deep/.el-collapse {
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;
        border: none;
      }
      /deep/.el-collapse-item__header {
        font-weight: 600;
        font-size: 16px;
        padding: 0 20px;
        box-sizing: border-box;
      }
      /deep/.conn {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 60px;
        box-sizing: border-box;
        > .title {
          width: auto;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 8px;
          i {
            font-size: 20px;
            cursor: pointer;
          }
        }
        > .imgs {
          width: 60%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 16px;
          > img {
            margin-right: 8px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
</style>