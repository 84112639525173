import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import test from '../views/test.vue'

import Main from '../views/main.vue'
import Login from '../views/login.vue'

import Elderly from '../views/elderly/index.vue'
import AddElderly from '../views/elderly/add.vue'
import QuestionnaireRecordElderly from '../views/elderly/questionnaireRecord.vue'
import CareElderly from '../views/elderly/care.vue'

import Equipment from '../views/equipment/index.vue'

import Employ from '../views/empoly/index.vue'

import Task from '../views/task/index.vue'
import TaskDetails from '../views/task/details.vue'

import Store from '../views/store/index.vue'

import Goods from '../views/shopping/goods/index.vue'
import GoodsConfig from '../views/shopping/goods_config/index.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'login',
    component: Login
}, {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
        {
            path: '/elderly',
            name: 'elderly',
            component: Elderly
        },
        {
            path: '/elderly/add',
            name: 'addElderly',
            component: AddElderly
        },
        {
            path: '/elderly/questionnaireRecord',
            name: 'questionnaireRecordElderly',
            component: QuestionnaireRecordElderly
        },
        {
            path: '/elderly/care',
            name: 'careElderly',
            component: CareElderly
        },
        {
            path: '/equipment',
            name: 'equipment',
            component: Equipment
        },
        {
            path: '/task',
            name: 'task',
            component: Task
        },
        {
            path: '/task/task-details',
            name: 'taskDetails',
            component: TaskDetails
        },
        {
            path: '/order',
            name: 'test',
            component: test
        },
        {
            path: '/customer',
            name: 'test',
            component: test
        },
        {
            path: '/goods',
            name: 'Goods',
            component: Goods
        },
        {
            path: '/goods-config',
            name: 'GoodsConfig',
            component: GoodsConfig
        },
        {
            path: '/employ',
            name: 'employ',
            component: Employ
        },
        {
            path: '/questionnaire',
            name: 'test',
            component: test
        },
        {
            path: '/store',
            name: 'store',
            component: Store
        },
        {
            path: '/notice',
            name: 'test',
            component: test
        },
    ]
}, {
    path: '/test',
    name: 'test',
    component: test
}]

const router = new VueRouter({
    routes
})

/**
 * 导航守卫
 */
router.beforeEach(async (to, from, next) => {
    // if (to.path != '/' && to.path.indexOf('test') < 0) {
    //     try {
    //         let route_now = to.path
    //         let data = await getAuthorityPath()
    //         let flag = false
    //         console.log("当前路由", route_now);
    //         data.forEach(element => {
    //             if (route_now == element) {
    //                 flag = true
    //             }
    //         });
    //         if (flag) {
    //             // 允许跳转
    //             next()
    //         } else {
    //             Message({
    //                 message: "您没有权限,请联系管理员!",
    //                 type: 'error'
    //             })
    //         }
    //     } catch (error) {

    //     }
    // } else {
    next()
    // }
})

//重复路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router