<template>
  <div id="goods"></div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
#goods{
    width: 100%;
    height: 100%;
    display: flex;
    
}
</style>