export function getMenuData() {
    return [
        {
            path: "",
            fullPath: "",
            query: {},
            name: "老人管理",
            icon: "el-icon-folder-opened",
            meta: {
                title: "老人管理",
            },
            children: [{
                path: "/elderly",
                fullPath: "/elderly",
                query: {},
                name: "老人管理",
                meta: {
                    title: "老人管理",
                    activeMenu: '老人管理'
                },
            }, {
                path: "/equipment",
                fullPath: "/equipment",
                query: {},
                name: "设备管理",
                meta: {
                    title: "设备管理",
                    activeMenu: '设备管理'
                },
            }, {
                path: "/task",
                fullPath: "/task",
                query: {},
                name: "任务管理",
                meta: {
                    title: "任务管理",
                    activeMenu: '任务管理'
                },
            },]
        },
         {
            path: "",
            fullPath: "",
            query: {},
            name: "商城管理",
            icon: "el-icon-sell",
            meta: {
                title: "商城管理",
            },
            children: [{
                path: "/order",
                fullPath: "/order",
                query: {},
                name: "订单管理",
                meta: {
                    title: "订单管理",
                    activeMenu: '订单管理'
                },
            }, {
                path: "/customer",
                fullPath: "/customer",
                query: {},
                name: "客户管理",
                meta: {
                    title: "客户管理",
                    activeMenu: '客户管理'
                },
            }, {
                path: "/goods",
                fullPath: "/goods",
                query: {},
                name: "商品管理",
                meta: {
                    title: "商品管理",
                    activeMenu: '商品管理'
                },
            },
            {
               path: "/goods-config",
               fullPath: "/goods-config",
               query: {},
               name: "商城配置",
               meta: {
                   title: "商城配置",
                   activeMenu: '商城配置'
               },
           },]
        },
         {
            path: "",
            fullPath: "",
            query: {},
            name: "系统管理",
            icon: "el-icon-setting",
            meta: {
                title: "系统管理",
            },
            children: [{
                path: "/employ",
                fullPath: "/employ",
                query: {},
                name: "员工管理",
                meta: {
                    title: "员工管理",
                    activeMenu: '员工管理'
                },
            }, 
             {
                path: "/questionnaire",
                fullPath: "/questionnaire",
                query: {},
                name: "问卷管理",
                meta: {
                    title: "问卷管理",
                    activeMenu: '问卷管理'
                },
            }, 
            {
                path: "/store",
                fullPath: "/store",
                query: {},
                name: "物品管理",
                meta: {
                    title: "物品管理",
                    activeMenu: '物品管理'
                },
            },
                // {
                //     path: "/notice",
                //     fullPath: "/notice",
                //     query: {},
                //     name: "公告中心",
                //     meta: {
                //         title: "公告中心",
                //         activeMenu: '公告中心'
                //     },
                // },
            ]
        },
    ]
}