import request from "@/utils/request";
let baseUrl = "/nursinghomes/elderly/";

/**
 * @description: 分页查询老人信息
 * @return {*}
 */
export async function getElderlyListByPage(data) {
  return await request({
    url: baseUrl + "getElderlyListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加或修改老人信息
 * @return {*}
 */
export async function addOrUpdateElderlyOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateElderlyOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条老人信息
 * @return {*}
 */
export async function getElderlyOneById(data) {
  return await request({
    url: baseUrl + "getElderlyOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}