<template>
  <div id="top-search">
    <!-- 顶部筛选界面 -->
    <div class="lf">
      <div class="item" v-for="item in data">
        <el-input
          v-model="item.value"
          clearable
          :placeholder="'请输入' + item.title"
          v-if="item.type == 'input'"
          @change="changeInputValue"
        ></el-input>
        <el-select
          v-if="item.type == 'select'"
          v-model="item.value"
          filterable
          clearable
          :placeholder="'请选择' + item.title"
          @change="changeInputValue"
        >
          <el-option
            v-for="it in item.options"
            :key="it.id"
            :label="it.value"
            :value="it.id"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-if="item.type == 'time'"
          v-model="item.value"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          value-format="yyyy-MM-dd"
          end-placeholder="结束日期"
          @change="changeInputValue"
        >
        </el-date-picker>
      </div>
      <div class="but" @click="handleClick('search')">
        <i class="el-icon-search"></i> 查询
      </div>
    </div>
    <div class="re">
      <div
        class="but"
        v-for="item in searchButton"
        @click="handleClick(item.key)"
      >
        <i :class="item.icon"></i> {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top_search",
  props: {
    searchData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    searchButton: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.searchData,
    };
  },
  watch: {
    searchData() {
      this.data = this.searchData;
    },
    data(newValue) {
      this.$emit("showSearchData", this.data);
    },
  },
  methods: {
    changeInputValue(val) {
      this.handleClick("search");
    },
    handleClick(key) {
      this.$emit("handleClick", key);
    },
  },
};
</script>

<style lang="less" scoped>
#top-search {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;

  > .lf {
    width: 0;
    flex: 1;
    height: auto;
    display: flex;
    align-items: center;

    > .item {
      width: 200px;
      height: 30px;
      line-height: 30px;
      margin: 0 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      /deep/.el-input,
      /deep/.el-input__inner,
      /deep/.el-select {
        width: 100%;
        height: 30px;
        line-height: 30px;
      }

      /deep/.el-input__prefix,
      /deep/.el-input__icon,
      /deep/ .el-date-editor .el-range-separator {
        line-height: 30px;
        display: flex;
        align-items: center;
      }

      /deep/.el-input__inner {
        border: none;
        background-color: #f1f1f1;
        border-radius: 2px;
      }

      /deep/ .el-date-editor .el-range-input {
        background-color: #f1f1f100;
      }
    }
  }
  > .re {
    width: auto;
    height: auto;
    display: flex;
  }

  .but {
    width: auto;
    height: auto;
    padding: 5px 13px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: #0256ff;
    color: #fff;
    > i {
      margin-right: 8px;
    }
  }
}
</style>