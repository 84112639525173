/**
 * 界面基础数据
 * @returns 
 */
export function baseData() {
    return {
        searchData: [
            {
                title: '员工姓名',
                value: "",
                type: "input",
                key: "userName"
            }
        ],
        searchButton: [
            {
                name: "新增",
                icon: "el-icon-circle-plus-outline",
                key: "addEmploy"
            }
        ],
        menuData: [
            {
                key: "userName",
                title: "姓名",
                width: ""
            },
            {
                key: "phoneNumber",
                title: "手机号",
                width: ""
            },
            {
                key: "loginName",
                title: "系统账号",
                width: ""
            },
            {
                key: "isEmployed",
                title: "在职状态",
                width: "80"
            },
            {
                key: "sex",
                title: "性别",
                width: "80"
            },
            {
                key: "age",
                title: "年龄",
                width: "120"
            },
            {
                key: "address",
                title: "家庭住址",
                width: ""
            },
        ],
        tableButton: [
            {title:"编辑",key:"edit"},
            {title:"删除",key:"del"},
            {title:"密码重置",key:"resetPsw"},
        ]
    }
}