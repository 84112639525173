<template>
  <div id="diy-table">
    <!-- 自定义表格 -->
    <el-table :data="data" stripe style="width: 100%" height="100%">
      <template v-for="menu in tableMenu">
        <el-table-column
          :prop="menu.key"
          :label="menu.title"
          :width="menu.width"
          ><template slot-scope="scope">
            <span v-if="menu.isChange">
              {{ changeCode(menu.key, scope.row[menu.key]) }}
            </span>
            <span v-else>
              {{ scope.row[menu.key] }}
            </span>
            
          </template>
        </el-table-column></template
      >
      <el-table-column fixed="right" label="操作" :width="width">
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row, but.key)"
            type="text"
            size="small"
            v-for="but in scope.row.tableButton"
            >{{ but.title }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "diy_table",
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableMenu: {
      type: Array,
      default: function () {
        return [];
      },
    },
    model: {
      type: String,
      default: function () {
        return "";
      },
    },
    width:{
      type: String,
      default: function () {
        return "120";
      },
    }
  },
  data() {
    return {
      data: this.tableData,
    };
  },
  watch: {
    tableData() {
      this.data = this.tableData;
    },
    data(newValue) {
      this.$emit("showTableData", this.data);
    },
  },
  methods: {
    handleClick(row, key) {
      this.$emit("handleClick", { row, key });
    },
    changeCode(key, code) {
      return this.$base.getStrByCode(this.model, key, code);
    },
  },
};
</script>

<style lang="less" scoped>
#diy-table {
  width: 100%;
  height: 100%;
}
</style>