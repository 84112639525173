import request from "@/utils/request";
let baseUrl = "/nursinghomes/task/";

/**
 * @description: 分页查询任务列表
 * @return {*}
 */
export async function getTaskListByPage(data) {
  return await request({
    url: baseUrl + "getTaskListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条任务信息
 * @return {*}
 */
export async function getTaskOneById(data) {
  return await request({
    url: baseUrl + "getTaskOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}